<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo mt-4 mb-4">
          <img
            :src="style.loginImage"
            width="150px"
          >
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('Esqueceu a senha') }}? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('Informe seu e-mail que enviaremos instruções para você redefinir sua senha') }}
        </b-card-text>

        <!-- form -->
        <FormulateForm
          ref="form"
          name="form"
          @submit="validationForm"
        >
          <!-- email -->

          <FormulateInput
            id="user-email"
            v-model="userEmail"
            type="email"
            class="required"
            :label="$t('E-mail/Login')"
            :placeholder="$t('Nome')"
            validation="required"
          />

          <FormulateInput
            v-if="showMarkeplace"
            id="operator"
            v-model="operator"
            class="required"
            validation="required"
            type="vue-select"
            :label="$t('Marketplace')"
            :options="hubs"
            :placeholder="$t('Selecione')"
            name="login-operator"
          />

          <!-- submit button -->
          <e-button
            variant="primary"
            block
            type="submit"
            :text="$t('Enviar instruções')"
            :busy="busy"
          />
        </FormulateForm>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" /> {{ $t('Voltar') }}
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { BCard, BLink, BCardText, BCardTitle } from 'bootstrap-vue'
import { required, email } from '@validations'
import { acl } from '@/mixins'

import useJwt from '@/auth/jwt/useJwt'
import EButton from '@/views/components/EButton.vue'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    EButton,
  },
  mixins: [acl],
  data() {
    return {
      userEmail: '',
      manySellers: false,
      hubs: [],
      operator: null,
      showMarkeplace: false,
      required,
      email,
      busy: false,
    }
  },
  methods: {
    async validationForm() {
      try {
        this.busy = true
        if (this.$refs.form.isValid) {
          if (this.operator) {
            await this.login()
          } else {
            await this.getMarketplacesByEmail()
            if (this.operator) {
              await this.login()
            }
          }
        }
      } catch (error) {
        this.showError({ message: this.$t('Não foi possível completar está operação') })
      } finally {
        this.busy = false
      }
    },

    async getMarketplacesByEmail() {
      try {
        const result = await this.$http.get(`/backend/v1/hub/marketplaces/${this.userEmail}`)
        this.hubs = result.data?.map(({ id, dadoHub }) => ({
          value: id,
          label: dadoHub.nomeFantasia,
        }))
        if (result.data.length === 1) {
          this.operator = this.hubs[0]?.value
          this.showMarkeplace = false
        } else {
          this.showMarkeplace = true
        }
      } catch (error) {
        this.showError({
          title: this.$t('Credenciais inválidas'),
          message: this.$t('Nenhum marketplace encontrado para o email informado'),
        })
      }
    },
    async login() {
      useJwt.setToken('')
      useJwt.setRefreshToken('')
      const data = {
        dadoUsuario: {
          login: this.userEmail,
          email: this.userEmail,
        },
        operador: this.operator,
      }
      const { data: response } = await this.$http.post(
        '/wayhub/v1/usuario/recuperarSenha?isSync=true',
        data
      )
      const { consequence, message } = response
      if (consequence === 'SUCESSO') {
        this.showSuccess({
          message: this.$t('Verifique sua caixa de entrada e siga as instruções'),
        })
        this.$router.push({ name: 'auth-login' })
      } else {
        this.showError({ message })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
